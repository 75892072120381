import React, { useEffect, useState } from 'react'
import Appbar from '../component/Appbar'
import { useDispatch, useSelector } from 'react-redux'
// import Points from '../../../../assets/icon/points.png'
// import NA from '../../../../assets/icon/na.png'
// import DailyStreak from '../../../../assets/img/daily_streak.png'
// import SpinIcon from '../../../../assets/img/spinwheel_small.png'
import { getUserTransactionAction } from '../../../../redux/user/getUserTransaction'
import moment from 'moment'
import Style from './history.module.scss'
import Styles from '../style/trending.module.scss'
import { favoriteMissionAction } from '../../../../redux/profile/favoriteMission'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { favoriteAction } from '../../../../redux/profile/favorite';
import { unfavoriteAction } from '../../../../redux/profile/unfavorite';
import { getUserDetailsAction } from '../../../../redux/user/getUserDetails';
import Play_btn from '../../../../assets/theme6/btn_img.png'
import Fav from '../../../../assets/theme6/fav1.svg'
import Unfav from '../../../../assets/theme6/unfav.svg'
import LimitPopup from '../component/LimitPopup';
import LimitPopup2 from '../component/LimitPopup2';
import { useNavigate } from 'react-router-dom'
import { getMissionDetailAction } from '../../../../redux/screen/missiondetails';





function Favorite() {
    // window.scrollTo(0, 0);
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const getUserTransaction = useSelector(state => state.getUserTransaction)
    // const { loading } = getUserTransaction
    const [limit, setLimit] = useState(8)
    const [page, setPage] = useState(0)
    const [Data, setData] = useState([]);
    const [DataLength, setDataLength] = useState(0)
    const favoriteMission = useSelector(state => state.favoriteMission)
    const { data, loading } = favoriteMission
    const [showPopup, setShowPopup] = useState(false);
    const userDetialsSelector = useSelector(state => state.getUserDetails)
    const { data: { daily_attempt_limit_count } } = userDetialsSelector
    const getAppSelector = useSelector(state => state.getApp)
    const { data: { is_ads_enabled }, Config: { daily_user_attempt_limit } } = getAppSelector
    const [isMore, setIsMore] = useState(false)



    const [isFav, setIsFav] = React.useState([])

    const [userDeviceWidth, setUserDeviceWidth] = useState(null)
    const [open1, setOpen1] = React.useState(false);

    const currentUrl = window.location.href;

    // console.log(currentUrl,"currentUrl---");




    useEffect(() => {
        // dispatch(getUserTransactionAction({ page: page,limit:limit }))
        // setData([...TransactionData])

        // const fetchData = async () => {
        //     try {
        //         let res = await dispatch(getUserTransactionAction({ page: page, limit: limit }));
        //         if (res?.payload?.status === 200) {
        //             setData(prevData => [...prevData, ...res?.payload?.data]);  
        //         }

        //     } catch (error) {
        //         console.log("Error:", error);
        //     }
        // };

        // nextHandler();
        fetchFavorite()
        fetchUserDetails()

        setUserDeviceWidth(window.innerWidth)


    }, [])

    const fetchFavorite = () => {
        dispatch(favoriteMissionAction())
    }

    const nextHandler = async () => {

        setPage((prevPage) => {
            return prevPage + 1;
        });


        let response = await dispatch(getUserTransactionAction({ page: page, limit: limit }))
        if (response?.payload?.status === 200) {
            setData([...Data, ...response?.payload?.data]);
            setDataLength(response?.payload?.totalLength)

        }

    }


    const favHandler = (value) => {
        // console.log("value", value)

        if (isFav.includes(value)) {
            setIsFav([...isFav.filter(item => item !== value)])
            dispatch(unfavoriteAction({ mission_id: value }))

        } else {
            setIsFav([...isFav, value])
            dispatch(favoriteAction({ mission_id: value }))
        }

    }

    const fetchUserDetails = async () => {

        const res = await dispatch(getUserDetailsAction())
        // console.log("res-->",res.payload.data.favorites)
        const data = res?.payload?.data?.favorites
        setIsFav([...data])


    }

    // const clickHandler=(item)=>{
    //     if(daily_attempt_limit_count>=daily_user_attempt_limit){
    //         setShowPopup(true)
    //     }else{
    //         navigate('/mission-detail', { state: { mission_id: item._id } }) 

    //     }
    // }


    const clickHandler = (item) => {
        if (daily_attempt_limit_count >= daily_user_attempt_limit) {
            setShowPopup(true)
        } else {

            dispatch(getMissionDetailAction(item._id))
                .then((res) => {

                    // console.log(res,"res")
                    if (res.payload.status !== 403)
                        navigate('/mission-detail', { state: { mission_id: item._id } })
                    else
                        setOpen1(true)




                })
                .catch((error) => {
                    // Handle any errors that occur during the dispatch
                    console.error('Error dispatching mission detail action:', error);
                });



        }
    }





    return (
        <>
            <Appbar />

            <div className={Style.main_div}>



                <div className={Style.history__heading}>
                    <h3>Favorite</h3>
                    {/* <div className={Style.view_all}>
                        <p><Link className={Style.link} to={'/history'}>View all</Link></p>
                    </div> */}

                </div>

                <div className={Styles.mission_sec}>

                    {loading ? <Box sx={{ width: "95%" }}>

                        <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                        <br />
                        <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                        <br />
                        <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                        <br />
                        <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />


                    </Box> : data?.length > 0 ? (data.map((item, index) => {

                        return (
                            // <div key={index}>
                            <>
                                <div className={Styles.continer} key={item?._id} >
                                    <div key={"fav" + item?._id} onClick={() => { favHandler(item?._id) }} style={{ position: "absolute", top: "0px", right: "0px" }}>
                                        {isFav.includes(item?._id) ? <img src={Fav} alt="fav" /> : <img src={Unfav} alt="fav2" />}

                                    </div>
                                    <div className={Styles.container1} key={item?._id}>
                                        <div className={Styles.image_div}>
                                            <img src={item?.icon} alt="icon" />
                                        </div>
                                        <div className={Styles.item_container}>
                                            <div className={Styles.Cash_bonanza_div}>{(userDeviceWidth > 450 && item?.title.length > 14) ? item?.title.substring(0, 14) + '...' : item?.title.substring(0, 18)}</div>
                                            <div className={Styles.Fast_food_div}>{item?.sub_category}</div>
                                            <div className={Styles.fast_food_detail_container}>
                                                <div className={Styles.food_detils} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><SmartDisplayOutlinedIcon style={{ color: "#b6b6b6", fontSize: "16px" }} /></div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item?.no_of_question}</div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;Q</div>
                                                </div>
                                                |
                                                <div className={Styles.food_detils} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><HourglassEmptyIcon style={{ color: "#b6b6b6", fontSize: "16px" }} /></div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item?.time_criteria}</div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;mins</div>
                                                </div>
                                                |
                                                <div className={Styles.food_detils} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><PersonOutlineOutlinedIcon style={{ color: "#b6b6b6", fontSize: "18px" }} /></div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;&nbsp;{item.participants}</div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={() => clickHandler(item)} className={Styles.button_9} role="button">
                                        Get {item.reward.toFixed(2)} {item.currency_name}
                                        <img className={Styles.btn_img} src={Play_btn} width={"60px"} height={"60px"} />
                                    </button>

                                </div>

                                {index !== item.length - 1 && is_ads_enabled && <div className={Styles.continer}>
                                    ads
                                </div>}

                            </>

                            // </div>
                        )
                    })) : (
                        <div style={{ marginTop: "20px", wordWrap: 'break-word', textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", width: "100%", height: "50vh" }}>
                            <p> No favourites right now</p>
                        </div>

                    )}

                    {/* {(!isMore && length >= 4) &&
                        <p className='view_more' onClick={() => viewMore()}>View All Mission</p>
                    } */}

                    {showPopup && <LimitPopup open={showPopup} setOpen={setShowPopup} />}
                    {open1 &&(<LimitPopup2 open={open1} setOpen={setOpen1} currentUrl={currentUrl} />)}


                </div>


            </div>



        </>
    )
}

export default Favorite