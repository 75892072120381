import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';


function ProtectedRoute({ children }) {
    const is_maintinance_mode=JSON.parse(localStorage.getItem("is_maintinance_mode"));
  

    const isAuthenticatedAppId = JSON.parse(localStorage.getItem("pwa_app_id"));
    const token = JSON.parse(localStorage.getItem("pwa_token"));

    
if (is_maintinance_mode) {
    return <Navigate to="/maintinance" />;
  }



    return isAuthenticatedAppId && token ? children : <Navigate to={`/`} />

}

export default ProtectedRoute;


ProtectedRoute.propTypes = {
    children: PropTypes.any,
};