import React from 'react'
import DailyProgressBar from './DailyProgressBar'
import DailyRewardComponent from './DailyRewardComponent'
import { useDispatch, useSelector } from 'react-redux'
import Appbar from '../../comman/Appbar'



const Index = () => {

  const userDetialsSelector = useSelector(state => state.getUserDetails)
  const { data } = userDetialsSelector

 
  return (
    <>
    <Appbar/>
    <div className='streak_background'>
      <div className='first__section'>
        <div className='heading_dailylogin'>Daily Login Rewards</div>
        <div className='dailyProgress'><DailyProgressBar streak={data?.logged_in_streak} /></div>
        </div>
      <div className='reward_background'>
        <DailyRewardComponent streak={data?.logged_in_streak} />
      </div>
     

    </div>
    </>
  )
}

export default Index