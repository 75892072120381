import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
// import styles from '../../../css/screens/Mission.module.scss'
import TrendingT from '../../../assets/img/mission.png'
import Ques from '../../../assets/icon/Vector (1).png'
import Time from '../../../assets/icon/Vector (2).png'
import User from '../../../assets/icon/Vector (3).png'
import Appbar from '../../../comman/Appbar'
import { getMissionActionBySubCat } from '../../../redux/screen/missionbysubcategory'
import LoadingUI from '../../../comman/LoadingUI'
import LimitPopup from '../popup/LimitPopup'

// import { getMissionAction } from '../../../redux/screen/mission'


// import './mission.css'


export default function Mission() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const sub_cat_id = location.state.sub_cat_id
    const subcatname = location.state.subcatname
    const getMissionSelector = useSelector(state => state.getMissionBySubCat)
    const { data: missionData, loading,status,message } = getMissionSelector
    const getAppSelector = useSelector(state => state.getApp)
    const {data:{is_ads_enabled},Config:{daily_user_attempt_limit}}=getAppSelector
    const userDetialsSelector = useSelector(state => state.getUserDetails)
    const { data:{daily_attempt_limit_count} } = userDetialsSelector
    const [showPopup, setShowPopup] = useState(false);

    

    if(status===401){
        alert(message)
    }

    useEffect(() => {
        dispatch(getMissionActionBySubCat(sub_cat_id))
        // dispatch(getMissionAction())
    }, [dispatch])

    const clickHandler=(item)=>{
        // console.log(daily_attempt_limit_count,daily_user_attempt_limit,"hello world")
        if(daily_attempt_limit_count>=daily_user_attempt_limit){
            setShowPopup(true)
        }else{
            navigate('/mission-detail', { state: { mission_id: item._id } }) 

        }
    }

    return (
        <>
            <div className="main_div">
                <div>
                <Appbar title='Missions' />

                <div className="banner">

                    <img src={TrendingT} style={{ zIndex: 3, marginTop: "10px" }} />
                    <p style={{ position: "absolute", top: "82px", zIndex: "5", fontFamily: "Gantari", fontWeight: "900", fontStyle: "italic", fontSize: "24px" }}>{subcatname}</p>

                </div>
                <div className='banner_sub_heading_mission'>
                        <p style={{fontSize:"22px",fontFamily:"'Roboto',sans-serif",fontWeight:"600"}}>{subcatname}</p>
                </div>
                <div className='mission_starting_text'>
                    <p>Select a quiz</p>
                </div>
                <div className="mission_sec">
                    {loading ? <LoadingUI /> : missionData?.length > 0 ? (missionData.map((item, index) => {

                        return (
                        <>
                            <div className="wrapper" key={index}>
                                <div className="continer">
                                    <div className="container1">
                                        <div className='image_div'>
                                            <img className="food_image" src={item?.icon} alt="" />
                                        </div>
                                        <div className="item_container">
                                            <div className="Cash_bonanza_div">{item?.title}</div>
                                            <div className="Fast_food_div">{subcatname}</div>
                                            <div className="fast_food_detail_container">
                                                <div className="food_detils">
                                                    <div><img src={Ques} /></div>
                                                    <div>&nbsp;&nbsp;{item?.no_of_question}</div>
                                                    <div>Q</div>
                                                </div>
                                                |
                                                <div className="food_detils">
                                                    <div><img src={Time} alt="" /></div>
                                                    <div>&nbsp;&nbsp;{item?.time_criteria}</div>
                                                    <div>mins</div>
                                                </div>
                                                |
                                                <div className="food_detils">
                                                    <div>&nbsp;&nbsp;<img src={User} width="14px" alt="" /></div>
                                                    <div>&nbsp;{item.participants}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="button_9" role="button" onClick={()=>clickHandler(item)}>
                                        Get {item?.reward.toFixed(2)} {item?.currency_name}
                                    </button>
                                </div>

                                
                            </div>
                            {index !== item.length - 1 && is_ads_enabled && <div className="continer">
                                ads
                            </div>}
                            
                        </>
                        )
                    })) : (<p style={{ marginTop: "40vh" }}>"Mission Not Available Right Now"</p>)}




                </div>
                </div>
      {showPopup && <LimitPopup open={showPopup} setOpen={setShowPopup}/>}

            </div>


        </>
    )
}
