import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Appbar from '../component/Appbar2'
import { useDispatch, useSelector } from 'react-redux'
import Points from '../../../../assets/icon/points.png'
import { getUserTransactionAction } from '../../../../redux/user/getUserTransaction'
import moment from 'moment'
import NA from '../../../../assets/icon/na.png'
import DailyStreak from '../../../../assets/img/daily_streak.png'
import SpinIcon from '../../../../assets/img/spinwheel_small.png'
import Coin from '../../../../assets/theme6/coin.svg'

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import Style from '../Profile/profile.module.scss'
import AvatarModal from './AvatarModal'
import Boy from '../../../../assets/theme6/boy.svg'
import Girl from '../../../../assets/theme6/girl.svg'
import Edit from '../../../../assets/theme6/profile_edit.svg'
import Mail from '../../../../assets/theme6/mail.svg'
import Call from '../../../../assets/theme6/call.svg'
import IBTN from '../../../../assets/theme6/i_btn.svg'
import Info from '../../../../assets/theme6/info.png'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';















export default function Profile() {
    // state
    window.scrollTo(0, 0);

    const [isAvatarModal, setIsAvatarModal] = useState(false)
    const [isInfo, setIsInfo] = useState(false)



    const dispatch = useDispatch()

    const getUserDetails = useSelector(state => state.getUserDetails)
    const { data: userData, status, message } = getUserDetails
    // console.log("userData-->", userData)

    const getUserTransaction = useSelector(state => state.getUserTransaction)
    const { data: TransactionData, loading } = getUserTransaction


    const getAppData = useSelector(state => state.getApp)
    const { data: appData } = getAppData
    const [userScreenWidth, setUserScreenWidth] = useState()



    // console.log("appData", appData)




    // fn
    // const handleProfile = async (data) => {
    //     let profileDetails = await dispatch(addProfileDetailsAction(data))
    //     if (profileDetails?.payload?.status == 200) {
    //         setSnack(true)
    //         navigate("/survey")
    //     }
    // }


    useEffect(() => {
        dispatch(getUserTransactionAction({ page: 0, limit: 5 }))

        setUserScreenWidth(window.innerWidth)
        // console.log(userScreenWidth, "userScreenWidth");
    }, [])

    const openAvatar = () => {

        setIsAvatarModal(true)
    }

    function getEmail(email){

       
       
        try{
       

        // console.log(userScreenWidth, "userScreenWidth");
       
        if(email?.length<=20 ){
            
            return userData?.email
        }else{
            
                let fontSize=8;
                let remainScreenSize=userScreenWidth-320;
                let charCanAdjust=Math.floor(remainScreenSize/fontSize);
                if(email.length<email.substring(0,20)+charCanAdjust){
                    return email.substring(0,20+charCanAdjust)
                }
                return email.substring(0,20+charCanAdjust-3)+'...'
        }
       
    }catch(error){
        console.log(error,"error")
    }

        
    }


    return (
        <>


            <div className={Style.newBackgroundImage}>
                <Appbar />

                <div className={Style.main_div}>

                    <div className={Style.container}>

                        <div className={Style.details__Area}>


                            <div className={Style.quizHeader}>

                                <div className={[Style.quizInfo, Style.mt20]} style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                                    <div className={Style.quizImage} >
                                        <img src={userData?.avatar_type == 1 ? Boy : Girl} width="100%" height="100%" alt="food" />

                                    </div>

                                    <div onClick={openAvatar} style={{ border: "2px solid greens", position: "relative", background: "#FA5743", width: "25px", height: "25px", left: "50px", bottom: "35px", zIndex: "111", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "4px" }}>
                                        <img src={Edit} width={"21px"} alt="edit_btn" s />
                                    </div>
                                    <div className={Style.quizdesc}>
                                        {/* <h4 className={Style.quizName}>{userData?.email?.charAt(0).toUpperCase()}</h4> */}
                                        <h5 className={Style.catName}>{userData?.name ? userData?.name : "---"}</h5>

                                    </div>
                                </div>

                                <div className={Style.earn}>
                                    {

                                        isInfo ? <div className={Style.info} onClick={() => { setIsInfo(false) }}><div><img className={Style.nestedibtn} onClick={() => { setIsInfo(true) }} src={IBTN} alt="i_btn" /><p>Coins have been credited to {appData.app_name}</p></div></div> : <img className={Style.ibtn} onClick={() => { setIsInfo(true) }} src={IBTN} alt="i_btn" />


                                    }
                                    <div className={Style.abs}>
                                        <div className={Style.coin}><img src={Coin} /><p>{userData?.reward ? userData?.reward.toFixed(2) : "---"}</p></div>
                                        <div className={Style.coinsEarned}><p>Coins Earned</p></div>
                                    </div>

                                </div>
                            </div>


                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: 'center', marginBottom: '20px' }}>
                                {/* <div className="profile__section">
                                <div className='round__profile__section'>
                                    <div className='profile_div' style={{ boxSizing: "border-box", width: "50px", height: "50px" }}>
                                        <p>{userData?.email?.charAt(0).toUpperCase()}</p>

                                    </div>
                                    <div className="profile__name">
                                        {userData?.name ? userData?.name : "---"}
                                    </div>
                                </div>
                            </div> */}



                                <div className={Style.profile__detail__section}>
                                    <div className={Style.email}>
                                        <div className={Style.logo}><img src={Mail} alt="mail" /></div>
                                        {userData?.email && <div className={Style.content} id='emailDiv'>Email:&nbsp;{getEmail(userData.email)}</div>}
                                       {/* {userData?.email && <div className={Style.content}>Email:&nbsp;{(userData?.email.length>19 && userScreenWidth<320)?userData?.email.substring(0, 16) + "...":(userData?.email.length>25 && userScreenWidth<420)?userData?.email.substring(0, 22) + "...":userData?.email}</div>} */}
                                       {/* {userData?.email && <div className={Style.content}>Email:&nbsp;{userData?.email ? userScreenWidth < 420 ? userData?.email.substring(0, 16) + "..." : userData?.email : "---"}</div>} */}
                                    </div>
                                    <div className={Style.number}>
                                        <div className={Style.logo}><img src={Call} alt="call" /></div>
                                        <div className={Style.content}>Registered Mobile:&nbsp;{userData?.mobile ? userData?.mobile : "---"}</div>
                                    </div>
                                    {/* <div className="coins p_detail">
                                    <div className="logo"><img src={Points} alt="coin" width="35px" height="35px" /></div>
                                    <div className="content" style={{ marginLeft: "-10px" }}>Total Earnings:&nbsp;{userData?.reward ? userData?.reward : "---"}</div>
                                </div> */}
                                </div>



                                <div className={Style.history__heading}>
                                    <h3>Quiz History</h3>
                                    <div className={Style.view_all}>
                                        <p><Link className={Style.link} to={'/history'}>View all</Link></p>
                                    </div>

                                </div>

                                {loading ? <Box sx={{ width: "95%" }}>

                                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                                    <br />
                                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                                    <br />
                                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />
                                    <br />
                                    <Skeleton style={{ borderRadius: "10px" }} animation="wave" variant="rectangular" width={"100%"} height={130} />


                                </Box> : TransactionData.length > 0 ? TransactionData.map((item, index) => (

                                    (item?.mission_id || item?.reward_type == 2 || item?.reward_type == 3) && (
                                        <div className={Style.history__card} key={index}>
                                            <div className={Style.history__pic}>
                                                {item?.mission_id && <img src={item?.mission_id?.icon ? item?.mission_id?.icon : NA} alt="icon" />}
                                                {item?.reward_type === 2 && <img src={DailyStreak} alt="icon" />}
                                                {item?.reward_type === 3 && <img src={SpinIcon} alt="icon" />}
                                            </div>
                                            <div className={Style.history__details}>
                                                <div className={Style.top}>
                                                    {/* {item?.mission_id && <div className={Style.name}>{(item?.mission_id?.title.length>11 && userScreenWidth<450) ? item?.mission_id?.title.substring(0, 11)+"..." : item?.mission_id?.title.substring(0, 18)}</div>} */}
                                                    {item?.mission_id && <div className={Style.name}>{(item?.mission_id?.title.length > 11 && userScreenWidth < 360) ? item?.mission_id?.title.substring(0, 11) + "..." : (item?.mission_id?.title.length > 14 && userScreenWidth < 420) ? item?.mission_id?.title.substring(0, 14) + "..." : item?.mission_id?.title.substring(0, 18)}</div>}

                                                    {item?.reward_type === 2 && <div className={Style.name}>{'Daily Login'}</div>}
                                                    {item?.reward_type === 3 && <div className={Style.name}>{'Spinwheel'}</div>}
                                                    <div className={Style.logo}>
                                                        <div className={Style.logo_pic}>
                                                            <img src={Points} width="18px" alt="logo" />
                                                        </div>
                                                        <div className={Style.logo_name}>
                                                            <p style={{ fontSize: "16px" }}>{item?.reward_amt.toPrecision(4)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={Style.middle}>{item?.mission_id?.cat_id?.cat_name ? item?.mission_id?.cat_id?.cat_name : 'Daily Reward'}</div>

                                                <div className={Style.bottom}>
                                                    <div>
                                                        Completed On&nbsp;{moment.utc(item.created_at).format("Do MMM YY")}
                                                    </div>
                                                    <div>
                                                        |
                                                    </div>
                                                    <div>
                                                        {moment.utc(item.created_at).format('h:mm a')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : <div className={Style.no__transaction}><h3>No transaction history available</h3></div>}


                            </div>
                        </div>
                    </div>

                </div>



                {isAvatarModal && <AvatarModal isAvatarModal={isAvatarModal} setIsAvatarModal={setIsAvatarModal} avatar_type={userData?.avatar_type} />}
            </div>

        </>

    )
}
