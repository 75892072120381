import React, { useEffect, useState } from 'react'
import Styles from './style/home.module.scss'
import Categories from './component/Categories'
import Trending from './component/Trending'
import Topimg from '../../../assets/theme6/top.png'
import Profile from '../../../assets/theme6/profile_icon.svg'
import Fav from '../../../assets/theme6/fav.svg'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material';
import Heart from '../../../assets/theme6/heart.svg'
import RocketIcon from '@mui/icons-material/Rocket';
import { getUserDetailsAction } from '../../../redux/user/getUserDetails'
import { useDispatch, useSelector } from "react-redux";
import { appAction } from '../../../redux/app/app'
import { getMissionDetailAction } from '../../../redux/screen/missiondetails'
import { useLocation, useNavigate } from 'react-router-dom'




const Theme6 = () => {
const navigate=useNavigate()
  // const lastLocationPath = document.referrer;
  // console.log(lastLocationPath);
  const dispatch=useDispatch()
  const [load,setLoad]=useState(false)

  const lastScreen = JSON.stringify(sessionStorage.getItem("lastScreen"))

  useEffect(()=>{
    try {

        const getUserDetailsFun=async()=>{
          let mission_id=JSON.parse(localStorage.getItem('mission_id'))
        
         


          const res=await dispatch(getUserDetailsAction())
          if(res.payload.status===201){
            let pwa_appId=JSON.parse(localStorage.getItem('pwa_app_id'))
            const Appres=await dispatch(appAction({appId:pwa_appId}))
            let redirectMissionId;
                if(mission_id){
                    redirectMissionId=mission_id
                }else{
                    redirectMissionId=Appres.payload.Config.redirect_mission_id
                }
            const missionDetail=await dispatch(getMissionDetailAction(redirectMissionId))
            // console.log("missionDetail-->",missionDetail.payload.data._id)
           
            // const missionRes=await dispatch(getMissionDetailAction(redirectMissionId))
            
            
                    if(res.payload.data.isFirstTime){
                        // navigate('/mission-detail', { state: { mission_id: redirectMissionId } }) 
                       
                         navigate(`/mission-qna?mission_id=${missionDetail.payload.data._id}&time_criteria=${missionDetail.payload.data?.time_criteria}&reward=${missionDetail.payload.data?.reward}`)

                    }else if(mission_id){
                      navigate(`/mission-qna?mission_id=${missionDetail.payload.data._id}&time_criteria=${missionDetail.payload.data?.time_criteria}&reward=${missionDetail.payload.data?.reward}`)

                    }
            }

            setLoad(true)

            
        }

        getUserDetailsFun()
        
     } catch (error) {
        console.log(error)
     }
},[])

 

  useEffect(() => {

    if (lastScreen.includes("mission-success") || lastScreen.includes("mission-unsuccess")) {
      

      window.location.hash = "no-back-button";
      window.location.hash = "Again-No-back-button";
      window.onhashchange = function () {
        window.location.hash = "no-back-button";
      }
    }
  }, [])

  return (
    <>
      <div className={Styles.parent} style={{ background: "#fff" }}>
        <div className={Styles.menu}>
          <div className={Styles.profile__div}>
            <Link to={'/profile'}>
              <img className={Styles.profile} width={"100%"} src={Profile} />
            </Link>

            <p>Profile</p>
          </div>
          <div className={Styles.profile__div}>
            <Link to={'/favorite'}>
              <img className={Styles.profile} width={"120%"} src={Fav} />
            </Link>

            <p>Favorites</p>
          </div>

        </div>
        <img width={"100%"} src={Topimg} />
        {/* {JSON.stringify(window.innerWidth + "screen width")}
        {JSON.stringify(window.innerHeight + "screen height")} */}
        <Categories />
        <Trending />

        <div className={Styles.bottom_text}>Pick, <br /> Play & <span className={Styles.color_text}>Earn</span></div>
        <div id="bottomDiv" className={Styles.jingle_text}>Made with <img style={{ position: 'relative', top: "5px" }} src={Heart} /> by QuizWall.IO</div>
        {/* <div className="rocket_color" style={{paddingBottom:"10px",borderRadius:"50%",fontSize:"16px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}><RocketIcon style={{fontSize:"48px"}} onClick={()=>{window.scrollTo(0,0);}}/><Typography sx={{fontFamily:"gantari"}}>Take me upp</Typography></div> */}
      </div>


    </>
  )
}

export default Theme6