import React from 'react'
// import './DailyRewards.css'
// import { ReactComponent as Coin } from "../../assets/newImages/bigGold.svg"
// import myster from "../../assets/newImages/mysteryStart.png"


function DailyRewardComponent({ streak }) {

    let totalDays = 7;

    const renderRewardComponent = () => {

        const dayDots = [];
        for (let day = 1; day <= totalDays; day++) {
            const isActive = day <= streak;
            dayDots.push(
                <div key={day} className='reward_component' >
                    <div className='reward_day'>Day {day}</div>
                    <div className='coinbox'>{(day === 4 || day === 7) ? 3 : 1} Coin</div>
                    <div className={`claimbox ${isActive ? 'active' : (day === 3 || day === 7) ? 'mysteryreward' : ''}`}>{`${isActive ? 'Claimed' : (day === 3 || day === 7) ? 'Mystery Reward' : 'Locked'}`}</div>
                </div>
            );
        }
        return dayDots;
    };
    return (
        <>
            <div className='rewardBar'>
                {renderRewardComponent()}
                <div style={{ width: "29%", maxWidth: "100px", maxHeight: "100px" }}>

                </div>
                <div style={{ width: "29%", maxWidth: "100px", maxHeight: "100px" }}>

                </div>

                {/* <div className='eightdayreward_component'>
                    <div className='mysterypic'><img src={"myster"} alt='mystery' /></div>
                    <div className='day_remained'>{streak < 3 ? (3 - streak) : streak < 7 && streak > 3 ? (7 - streak) : 4} Day Remaining</div>
                    <div className='mystery_text'>Mystery Reward</div>
                </div> */}
                <div className='eightdayreward_component'>

                </div>
            </div>
        </>
    )
}

export default DailyRewardComponent