import React, { useEffect, useState } from 'react'
import ProgressBar from "./progressBar";
import ImaegeComp from "./ImageComp";
import VideoComp from "./VideoComp";
import RaidoComp from "./RadioComp";
import CompCheck from "./CompCheck";
import AudioComp from './AudioComp';
import Timer from "./Timer";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate} from 'react-router-dom'
import { submitAction } from "../../../redux/screen/submit";
import ExitPopup from './ExitPopup';
import CryptoJS from 'crypto-js';



function LayoutComp({
  _id,
  question,
  video,
  indexValue,
  length,
  image,
  next,
  instruction,
  answer_type,
  ans_options,
  submitAnswer,
  type,
  mission_id,
  setSubmitAnswer,
  correctAnswer,
  setCorrectAnswer,
  timeTaken,
  file_url,
  data,
  timer


}) {
  const dispatch = useDispatch()

  // const [totalCorrect,setTotalCorrect]=useState(0)
  const navigate = useNavigate()
  const [options, setOptions] = useState([])
  const [second, setSecond] = useState(false);
  const [flag, setFlag] = useState(true)
  const [showPopup, setShowPopup] = useState(false);
  const getSubmitSelector = useSelector(state => state.submit)
  const { data: submitData, loading } = getSubmitSelector
  const [submitDisable, setSubmitDisable] = useState(false)
  const [propPassed, setPropPassed] = useState(false);
  const [scrollBottom, setScrollBottom] = useState(false)
  const getAppSelector = useSelector(state => state.getApp)
  const { data: { is_ads_enabled } } = getAppSelector
  

  const handlePropPassed = () => {
    setPropPassed(true);
  };



  function getRadioData(rdata) {
  }

  function getCheckBoxesData(cdata) {
    if (cdata.food) {
      setFlag(true)
    }
  }


  async function nextClickHandler() {

    let matched;
    if (answer_type == 'MCQ') {
      matched = options.every(option =>
        ans_options.some(item => item.option === option && item.correct === true)
      );

    } else {

      matched = ans_options.some(item => item.option === options && item.correct === true);


    }
    if (matched) {
         await setCorrectAnswer(prev=>prev+1)
    }

    // submitAnswer.push({ _id: _id, option: options })
    next();
    setSecond(true);

    setOptions([])

  }

  useEffect(() => {
    if (scrollBottom) {

      window.scrollTo(0, 10000)
    }
  }, [scrollBottom])

  const submitHandler = async () => {
    // submitAnswer.push({ _id: _id, option: options })

    let totalCorrect=correctAnswer
    let matched;
    if (answer_type == 'MCQ') {
      matched = options.every(option =>
        ans_options.some(item => item.option === option && item.correct === true)
      );

    } else {

      matched = ans_options.some(item => item.option === options && item.correct === true);


    }
    if (matched) {

      totalCorrect++


      await setCorrectAnswer(prev=>prev+1)

    }

    const secretKey = process.env.REACT_APP_MY_SECRET_KEY
    // console.log("secretKey===>",secretKey)
    const dataObj = { "mission_id": mission_id,"timeTaken": timeTaken,"correctAnswer":totalCorrect};
  


    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(dataObj), secretKey).toString();


    const submitDataRes = await dispatch(submitAction({ encryptedData: encryptedData}))
    // const submitDataRes = await dispatch(submitAction({ "mission_id": mission_id, "correctAnswer": correctAnswer, "timeTaken": timeTaken }))


    if (submitDataRes?.payload?.status === 201) {

      navigate('/mission-success')
    }
    else {
      navigate('/mission-unsuccess')

    }


  }

  // useEffect(()=>{
  //   totalCorrect==0
  //   setTotalCorrect(prev=>prev+1)
  // },[correctAnswer])



  
  return (
    <>
      <div className="wrapper_layout">
        {is_ads_enabled && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", background: "#fff" }}>ads</div>}
        <div className="cszbo"><span></span>Cash Bonanza <span style={{ padding: "5px", right: "5px", fontSize: "2rem" }} onClick={() => { setShowPopup(true) }}>✖</span></div>
        <div style={{ width: "100%", height: "1px", background: "#fff" }}> </div>
        <div className="container_layout">
          <div className="quesind-timer">
            <div className="que-indicator">
              <div className="indicator-color"> Question : {indexValue + 1}</div>{" "}
              <div className="divide-totalno-ques">/{length}</div>
            </div>
            {timer >= 0 && <Timer
              timer={timer}
            />}


          </div>
          {<ProgressBar colorpercent={((indexValue) * 100) / length} />}
          <div className="">{instruction}</div>
          <div className="question">{question}</div>

          {type === 'image' && <ImaegeComp image={file_url} />}
          {type === 'audio' && <AudioComp audio={file_url} />}
          {type === 'video' && <VideoComp video={file_url} />}



          {answer_type == 'optional' ? (
            <RaidoComp getRadioData={ans_options} options={options} setOptions={setOptions} setScrollBottom={setScrollBottom} />
          ) : (
            <CompCheck getCheckBoxesData={ans_options} options={options} setOptions={setOptions} setScrollBottom={setScrollBottom} />
          )}

          {
            options.length !== 0 && flag && (length - 1 > indexValue ? (
              <div>
                <div className="pink-button" onClick={nextClickHandler}>
                  next
                </div>

              </div>
            ) : (
              <div>
                <button className="pink-button" disabled={submitDisable} onClick={() => { setSubmitDisable(true); submitHandler() }}>submit</button>

              </div>
            ))}
        </div>
      </div>
      {is_ads_enabled && <div style={{ display: "flex", justifyContent: "center", background: "#fff" }}>ads</div>}

      {showPopup && <ExitPopup open={showPopup} setOpen={setShowPopup} missionId={mission_id} />}

    </>
  );
}

export default LayoutComp;
