import React,{useEffect,useState} from 'react'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
// import styles from '../../../css/screens/Subcategory.module.scss'
import Appbar from '../../../comman/Appbar'
import { useNavigate,useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getMissionDetailAction } from '../../../redux/screen/missiondetails'
import LimitPopup2 from '../popup/LimitPopup2';
// import './missiondetails.css'
function QuizBox() {
    const navigate=useNavigate()
    const dispatch = useDispatch()
    const location=useLocation()
    const [open,setOpen]=useState(false)
    const mission_id=location.state.mission_id;
    const missionDetail = useSelector(state => state.getMissionDetail)
    
    const { data: missionDetailData, loading: missionDetailLoading,status,message } = missionDetail
    
    
    if(status===401){
        alert(message)
    }
    useEffect(() => {
        dispatch(getMissionDetailAction(mission_id))
    }, [dispatch])
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };

      useEffect(() => {
        scrollToTop();
      }, [dispatch])

  

    return (
        <>
            <div className='main_div'>
            <Appbar title='Mission Details' />

              {status==200 &&  <div className='oneQuizCover'>
                    <div className="quizHeader">
                        <div className="headArea flex">
                            

                        </div>
                        <div className='quizInfo flex mt20'>
                            <div className="quizImage flex">
                                <img src={missionDetailData?.icon} width="85px" height="85px" alt="food" />
                            </div>
                            <div className="quizdesc">
                                <h4 className="quizName">{missionDetailData.title}</h4>
                                <h5 className='catName'>{missionDetailData?.sub_cat_id?.sub_cat_name}</h5>

                            </div>
                        </div>
                    </div>

                    <div className="oneQuizInfo">
                        <div className="pointsBox flex">
                            <div className="heroPoint">
                            <SmartDisplayOutlinedIcon/>

                                <h5>{missionDetailData.no_of_question} Q</h5>
                            </div>
                            <div className="heroPoint">
                                <HourglassEmptyIcon/>

                                <h5>{missionDetailData.time_criteria} Mins</h5>
                            </div>
                            <div className="heroPoint">
                            <PersonOutlineOutlinedIcon/>

                                <h5>{missionDetailData.participants}</h5>
                            </div>
                        </div>

                        <div className="infoPoint">
                            <div style={{height:"100vh",paddingLeft:"10px"}} dangerouslySetInnerHTML={{ __html: missionDetailData.description }}></div>
                        </div>
                        {/* <div className="infoPoint flex">
                            <p>Winning every round will take you into finals of this Tournament, where winning amount is waiting for you</p>
                        </div> */}
                    <div className="reward oneQuizReward" onClick={() => navigate(`/mission-qna?missionId=${missionDetailData?._id}&time_criteria=${missionDetailData?.time_criteria}`,{state:{mission_id:missionDetailData?._id,time_criteria:missionDetailData.time_criteria}})}><p>Get {Number(missionDetailData.reward).toFixed(2)} {missionDetailData?.currency_name}</p></div>
                    </div>
                </div>}|
            {status==403 && <LimitPopup2 open={true} setOpen={setOpen}/>}
            </div>
        </>
    )
}

export default QuizBox