import React from 'react'

function DailyProgressBar({ streak }) {
    const totalDays = 7;
    const renderDayDots = () => {
        const dayDots = [];
        for (let day = 1; day <= totalDays; day++) {
            const isActive = day <= streak;
            dayDots.push(
                <span key={day} className={`dayDot ${isActive ? 'active' : ''}`} />
            );
        }
        return dayDots;
    };
    return (
        <>
            <div className='progressBar'>
                {renderDayDots()}
            </div>
            <h5 className='days'>Day {streak} / 7</h5>
        </>
    )
}

export default DailyProgressBar