import React, { useEffect, useState } from 'react'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
// import styles from '../../../css/screens/Subcategory.module.scss'
// import Appbar from '../../../../comman/Appbar'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getMissionDetailAction } from '../../../../redux/screen/missiondetails'
import LimitPopup2 from '../component/LimitPopup2';
import Appbar from '../component/Appbar';
import Style from '../style/missiondetails.module.scss'
import Button from '../../../../assets/theme6/start_btn.svg'
// import './missiondetails.css'
function Mission_details() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const mission_id = location.state.mission_id;
    const missionDetail = useSelector(state => state.getMissionDetail)

    const { data: missionDetailData, loading: missionDetailLoading, status, message } = missionDetail

    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: smooth scrolling animation
    });

    if (status === 403) {
        navigate('/home')
    }

    if (status === 401) {
        alert(message)
    }
    useEffect(() => {
        dispatch(getMissionDetailAction(mission_id))
    }, [dispatch])
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollToTop();
    }, [dispatch])



    return (
        <>
            <Appbar />

            <div className={Style.main_div}>

                {status == 200 &&
                    <div className={Style.container}>

                        <div className={Style.details__Area}>

                            <div className={Style.quizHeader}>

                                <div className={Style.quizInfo}>
                                    <div className={Style.quizImage}>
                                        <img src={missionDetailData?.icon} width="100%" height="100%" alt="food" />
                                    </div>
                                    <div className={Style.quizdesc}>
                                        <h4 className={Style.quizName}>{missionDetailData.title}</h4>
                                        <h5 className={Style.catName}>{missionDetailData?.sub_cat_id?.sub_cat_name}</h5>

                                    </div>
                                </div>
                            </div>

                            <div className={Style.oneQuizInfo}>
                                <div className={Style.pointsBox}>
                                    <div className={Style.heroPoint}>
                                        <SmartDisplayOutlinedIcon className={Style.white} />

                                        <h5>{missionDetailData.no_of_question} Q</h5>
                                    </div>
                                    <div className={Style.heroPoint}>
                                        <HourglassEmptyIcon className={Style.white} />

                                        <h5>{missionDetailData.time_criteria} Mins</h5>
                                    </div>
                                    <div className={Style.heroPoint}>
                                        <PersonOutlineOutlinedIcon className={Style.white} />

                                        <h5>{missionDetailData.participants}</h5>
                                    </div>
                                </div>

                                <div className={Style.infoPoint}>
                                    <div style={{
                                        color: "#FFF",
                                        // fontfamily: "Roboto",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "14px",
                                        letterSpacing: "-0.24px"
                                    }} dangerouslySetInnerHTML={{ __html: missionDetailData.description }}></div>
                                </div>

                                {/* <div className={Style.reward} onClick={() => navigate(`/mission-qna?missionId=${missionDetailData?._id}&time_criteria=${missionDetailData?.time_criteria}`, { state: { mission_id: missionDetailData?._id, time_criteria: missionDetailData.time_criteria } })}><p>Get {Number(missionDetailData.reward).toFixed(2)} {missionDetailData?.currency_name}</p></div> */}
                                {/* <div className={Style.reward} onClick={() => navigate(`/mission-qna?missionId=${missionDetailData?._id}&time_criteria=${missionDetailData?.time_criteria}`, { state: { mission_id: missionDetailData?._id, time_criteria: missionDetailData.time_criteria } })}><img src={Button} alt="btn_image" /></div> */}
                                {/* <div className={Style.reward} onClick={() => navigate(`/mission-qna`, { state: { mission_id: missionDetailData?._id, time_criteria: missionDetailData.time_criteria, reward: missionDetailData.reward } })}><img src={Button} alt="btn_image" /></div> */}
                                
                                <div className={Style.reward} onClick={() => navigate(`/mission-qna?mission_id=${missionDetailData?._id}&time_criteria=${missionDetailData.time_criteria}&reward=${missionDetailData.reward}`)}><img src={Button} alt="btn_image" /></div>

                            </div>

                        </div>




                    </div>}
                {status == 403 && <LimitPopup2 open={true} setOpen={setOpen} />}
            </div>
        </>
    )
}

export default Mission_details