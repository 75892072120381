import * as React from 'react';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
// import { exitMissionAction } from '../../../../redux/screen/exitmission';
import { exitMissionAction } from '../../../../../redux/screen/exitmission';
// import Logo from '../../../../assets/icon/favicon.png'
import Styles from './ExitPopup.module.scss'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExitPopup({ open, setOpen,missionId}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()


// console.log("hello world");

    const handleClose = () => {
        setOpen(false);
    };


    const handleExit=async()=>{
        const exit=await dispatch(exitMissionAction(missionId))
        if(exit.payload.status===201){
    localStorage.removeItem("mission_id")

            setOpen(false)
            navigate('/home')
        }
        
      }

    return (


        <div
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            className={Styles.parent}

        >



            {/* <DialogTitle style={{display:"flex",justifyContent:"center"}}><img src={Logo} width="70px"/></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{textAlign:"center",fontFamily:"Roboto!important"}}>
          You have exceeded your daily limit for this Mission completion. Please come back again after 12:00 A.M. or Try Other Mission
          </DialogContentText>
        </DialogContent>*/}
            <div className={Styles.main}>
                {/* <DialogActions className="dailogue_bg" style={{ display: "flex", justifyContent: "center" }} onClick={handleClose} > */}

                <h2 className={Styles.heading}>OOPS!!</h2>
                
                <div>
                
                <h4>Would you like to quit this mission?</h4>
                <p className={Styles.para}>
                Quitting the mission in between will clear your past responses.
                </p>
                {/* Your daily attempt limit for this mission has been exhausted. Please try again after 12:00 P.M. or Try other mission. */}
                </div>


                {/* </DialogActions> */}

                <div className={Styles.btnDiv}>

                <button className={`${Styles.btn} ${Styles.btn1}`} onClick={() => handleExit()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none">
<path d="M12.3687 5.83832L4.47392 1.60303M10.7897 10.7795L1.63181 10.7795M12.3687 15.3677L7.00023 18.8971" stroke="#FA5743" stroke-width="2.11765" stroke-linecap="round"/>
</svg>
            <p>Quit</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none">
<path d="M1.31592 5.83832L9.21065 1.60303M2.89487 10.7795L12.0528 10.7795M1.31592 15.3677L6.68434 18.8971" stroke="#FA5743" stroke-width="2.11765" stroke-linecap="round"/>
</svg>
                </button>

                <button className={Styles.btn} onClick={() => { setOpen(false)}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                <path d="M14 5.83832L5.17647 1.60303M12.2353 10.7795L2 10.7795M14 15.3677L8 18.8971" stroke="white" stroke-width="2.11765" stroke-linecap="round" />
            </svg><p>Cancel</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                    <path d="M2 5.83832L10.8235 1.60303M3.76471 10.7795L14 10.7795M2 15.3677L8 18.8971" stroke="white" stroke-width="2.11765" stroke-linecap="round" />
                </svg></button>

                </div>

            </div>
            {/* <div> */}
            
            

        </div>

    );
}
