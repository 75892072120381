
import { Stack, Typography, } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { PropTypes } from "prop-types";
import * as React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import HistoryIcon from '../assets/icon/history.png';
import { getUserDetailsAction } from '../redux/user/getUserDetails';
import TimelineIcon from '@mui/icons-material/Timeline';

export default function Appbar({ title }) {
    // state
    const dispatch=useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation();


    const userDetialsSelector = useSelector(state => state.getUserDetails)
    const { data } = userDetialsSelector

    

    // fn
    

    React.useEffect(()=>{
        dispatch(getUserDetailsAction())
    },[])

    const handleLogout = () => {
        // localStorage.removeItem('pwa_user_id')
        // localStorage.removeItem('pwa_publisher_id')
        localStorage.removeItem('pwa_token')
        localStorage.removeItem('pwa_app_theme')
        localStorage.removeItem('pwa_app_id')
        window.location.reload();

    }
    const backHistory=()=>{
        if(pathname.includes('/home')){
            // Redirect to a new URL
window.location.href = localStorage.getItem('referringURL');

        }else{
            navigate(-1)
        }
    }

// console.log("pathname==>",pathname)

    return (
        <AppBar className="app__bar" position="sticky" sx={{ height: 70, bgcolor: '#fff', display: 'flex', justifyContent: 'center' }}>
            <Stack direction="row" sx={{ margin: "0px 20px",display:"flex",justifyContent: 'space-between' }}>
                <div style={{display:'flex',gap:"5px"}}>
                    <div className="typo" style={{ marginTop: "4px", color: "#000" }} width="15px" height="15px" /*onClick={() => { navigate(-1) }}*/ onClick={backHistory}><WestIcon /></div>
                    <Typography className="typo" sx={{ fontFamily: "'Gantari', sans-serif", fontStyle: "normal", fontSize: "20px", color: "#2C2C2C"}}>{title||"Quizwall"}</Typography>
                </div>

                {!pathname.includes('profile') && <div style={{display:'flex',gap:"18px"}}>
                  {/* {pathname.includes('home') && <div className="history_div">
                        <Link to={'/dailylogin'}><TimelineIcon /></Link>
                    </div>} */}
                    <div className="history_div">
                        <Link to={'/history'}><img src={HistoryIcon} alt="history_icon" width="26px" height="26px" /></Link>
                    </div>
                    <div className='profile_div'>
                       <Link to={'/profile'}><p>{data?.email?.charAt(0).toUpperCase()}</p></Link>
                    </div>

                </div>}
                {/* <Button onClick={handleLogout} variant="outlined">logout</Button> */}
            </Stack>
        </AppBar >
    )
}


Appbar.propTypes = {
    title: PropTypes.string,
};